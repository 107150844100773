<template>
  <div>
    <!-- miraeclass.com/athome이 wallpeckers.miraeclass.com/athome으로 변경되었습니다. 새 주소로 이동합니다. -->
  </div>
</template>

<script>
export default {
  name: 'AtHome',
  beforeCreate () {
    console.log('redirecting')
    window.location = 'https://wallpeckers.miraeclass.com/athome/', true
    return false
  }
}
</script>

<style scoped>
</style>
